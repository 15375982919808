var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('headerNav',{attrs:{"activeIndex":"3"}}),_c('div',{staticClass:"designBody"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"advantages"},[_c('div',{staticClass:"title"},[_vm._v("服务优势")]),_c('div',{staticClass:"cardBody"},[_c('div',{staticClass:"cardBodyDiv"},[_vm._m(2),_c('div',{staticClass:"parkTitle"},[_vm._v("全栈设计经验")]),_c('div',{staticClass:"parkSpan",staticStyle:{"margin-top":"6px"}},[_vm._v("多端设计能力")]),_c('div',{staticClass:"parkSpan"},[_vm._v("全品牌方案输出")]),_c('router-link',{attrs:{"to":"/contact"}},[_c('div',{staticClass:"parkBtn"},[_vm._v("与我们合作")])])],1),_c('div',{staticClass:"cardBodyDiv"},[_vm._m(3),_c('div',{staticClass:"parkTitle"},[_vm._v("定制化设计")]),_c('div',{staticClass:"parkSpan",staticStyle:{"margin-top":"6px"}},[_vm._v("私人定制")]),_c('div',{staticClass:"parkSpan"},[_vm._v("满足更多个性需求")]),_c('router-link',{attrs:{"to":"/contact"}},[_c('div',{staticClass:"parkBtn"},[_vm._v("与我们合作")])])],1),_c('div',{staticClass:"cardBodyDiv"},[_vm._m(4),_c('div',{staticClass:"parkTitle"},[_vm._v("1对1服务")]),_c('div',{staticClass:"parkSpan",staticStyle:{"margin-top":"6px"}},[_vm._v("专业UX设计师")]),_c('div',{staticClass:"parkSpan"},[_vm._v("一对一对接服务")]),_c('router-link',{attrs:{"to":"/contact"}},[_c('div',{staticClass:"parkBtn"},[_vm._v("与我们合作")])])],1)])]),_vm._m(5)]),_c('contactNav'),_c('footerNav')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"designImg"},[_c('div',{staticStyle:{"padding-top":"119px"}},[_vm._v("创意设计服务")]),_c('div',{staticStyle:{"margin-top":"32px"}},[_c('span',{staticStyle:{"width":"620px","font-size":"40px","display":"inline-block"}},[_vm._v("从现在开始，用更富魅力的方法， 敲响客户的大门。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"designText"},[_c('div',{staticClass:"title"},[_vm._v("服务介绍")]),_c('div',{staticClass:"spanText"},[_c('div',{staticStyle:{"margin-right":"15px"}},[_c('img',{staticStyle:{"width":"12px","height":"12px"},attrs:{"src":require("../../assets/img/designText.png")}})]),_c('div',[_vm._v("团队设计师来自互联网大厂，多年设计经验，熟悉互联网各类产品的设计流程，对产品规划，交互视觉方面有很成熟的经验。")])]),_c('div',{staticClass:"spanText"},[_c('div',{staticStyle:{"margin-right":"15px"}},[_c('img',{staticStyle:{"width":"12px","height":"12px"},attrs:{"src":require("../../assets/img/designText.png")}})]),_c('div',[_vm._v("设计环节前期包含调研、关键词定义、情绪版构建、设计语言提炼；中期是界面设计、规范文档、设计延展；后期有蓝湖插件标注切图，落地支持、项目维护等等。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"parkDiv"},[_c('div',{staticClass:"parkDivImg"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"parkDiv"},[_c('div',{staticClass:"parkDivImg1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"parkDiv"},[_c('div',{staticClass:"parkDivImg2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"successful"},[_c('div',{staticClass:"title"},[_vm._v("成功案例")]),_c('div',{staticClass:"cardBody"},[_c('div',{staticClass:"cardBodyDiv"},[_c('img',{staticStyle:{"border-radius":"16px 16px 0px 0px"},attrs:{"src":require("../../assets/img/successful1.png")}}),_c('div',{staticClass:"cardTitle"},[_vm._v("达美汽车供应链APP管理系统")]),_c('div',{staticClass:"span"},[_vm._v("一款管理汽车订单销售的app")])]),_c('div',{staticClass:"cardBodyDiv"},[_c('img',{staticStyle:{"border-radius":"16px 16px 0px 0px"},attrs:{"src":require("../../assets/img/successful2.png")}}),_c('div',{staticClass:"cardTitle"},[_vm._v("建投建材集团下的“经营管理平台”")]),_c('div',{staticClass:"span"},[_vm._v("帮助集团梳理业务数据")])]),_c('div',{staticClass:"cardBodyDiv"},[_c('img',{staticStyle:{"border-radius":"16px 16px 0px 0px"},attrs:{"src":require("../../assets/img/successful3.png")}}),_c('div',{staticClass:"cardTitle"},[_vm._v("费控报销APP财费系统")]),_c('div',{staticClass:"span"},[_vm._v("一款解决企业报销问题的app工具")])]),_c('div',{staticClass:"cardBodyDiv"},[_c('img',{staticStyle:{"border-radius":"16px 16px 0px 0px"},attrs:{"src":require("../../assets/img/successful4.png")}}),_c('div',{staticClass:"cardTitle"},[_vm._v("财务系统管理后台")]),_c('div',{staticClass:"span"},[_vm._v("管理财务订单和状态的后台系统")])])])])
}]

export { render, staticRenderFns }